import { Link } from 'gatsby';
import React from 'react';
import Date from '../date';

import './ArticleList.css';

const ArticleListItem = ({ date, title, path }) => {
  return (
    <li className="article-list-item">
      <h2 className="article-list-item__headline">
        <Link to={path} className="article-list-item__link">
          {title}
        </Link>
      </h2>

      <div className="article-list-item__meta">
        <Date date={date} />
      </div>
    </li>
  );
};

export default ArticleListItem;
