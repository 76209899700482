import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/layout';
import Meta from '../components/meta';
import Content from '../components/content';
import { ArticleList, ArticleListItem } from '../components/article-list';

const Notes = () => {
  const data = useStaticQuery(
    graphql`
      {
        allFile(
          filter: {
            sourceInstanceName: { eq: "notes" }
            internal: { mediaType: { eq: "text/markdown" } }
            childMarkdownRemark: { frontmatter: { published: { eq: true } } }
          }
          sort: { order: DESC, fields: childMarkdownRemark___frontmatter___date }
        ) {
          nodes {
            childMarkdownRemark {
              frontmatter {
                title
                path
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `
  );

  const { nodes } = data.allFile;

  return (
    <Layout>
      <Meta
        title="Notes"
        description="Random things I always need, but never remember."
        image="/images/og-image-notes.png"
      />

      <Content>
        <h1>Notes</h1>
        <p>Random things I always need, but never remember.</p>
        <ArticleList>
          {nodes.map((node, i) => {
            const { date, title, path } = node.childMarkdownRemark.frontmatter;
            return <ArticleListItem key={i} date={date} title={title} path={path} />;
          })}
        </ArticleList>
      </Content>
    </Layout>
  );
};

export default Notes;
